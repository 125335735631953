.editor {
  padding: 1rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.875rem 2.5rem;
    margin-bottom: 1.875rem;

    &._full-size {
      display: flex;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin-bottom: 1.875rem;
  }

  > .subtitle {
    margin-bottom: 1.875rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: auto;
    padding-top: 1.875rem;

    &._left {
      justify-content: flex-start;
    }

    &._right {
      justify-content: flex-end;
    }

    button {
      width: 15rem;
      height: 3rem;
    }
  }

  .form-control {
    min-width: 0;
    flex: 0 0 auto;

    > mat-form-field {
      width: 100%;
    }
  }

  .view-control {
    align-self: stretch;
  }
}
