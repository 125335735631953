@import "typography";

.table-page {
  .search {
    width: 16rem;
  }

  .filters {
    display: flex;
    align-items: center;
    padding-bottom: 1.25rem;
    gap: 1.5rem;
    flex-wrap: wrap;

    &__controls {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }

    &__actions {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    ._right {
      margin-left: auto;
    }
  }
}
