@import "typography";

.dialog {
  padding: 2.5rem 2.875rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title {
    @include typography-h5;
    text-align: left;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: auto;
    padding-top: 1.875rem;

    button {
      width: 15rem;
      height: 3rem;
    }
  }
}
