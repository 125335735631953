@import "vars";
@import "typography";

html {
  font-size: 16px;

  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1400px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-family: $font-family-base;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* > * {
  box-sizing: inherit;
}

body {
  @include typography-body-2;
  box-sizing: border-box;
  color: var(--theme-text-main);

  /* total width */
  ::-webkit-scrollbar {
    background-color: var(--theme-scrollbar-bg);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;

    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: var(--theme-scrollbar-bg);
    border-radius: 1rem;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-scrollbar-thumb);
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
}

input {
  font-family: inherit;
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.subtitle {
  @include typography-subtitle-1;
}
