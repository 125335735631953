@import "../vars/typography";
@import "../vars/palette";

body {
  /* Base text */
  --theme-primary: #343E48;
  --theme-text-accent: #72879E;
  --theme-text-main: #263238;
  --theme-text-secondary: #8E9398;
  --theme-text-third: #B4B5B5;
  --theme-text-error: #B00020;
  --theme-text-danger: #D52833;
  --theme-text-red: #F94C4C;

  /* Alerts */
  --theme-alert-danger-bg: #f1dbda;
  --theme-alert-danger-color: #A90E46;

  --theme-alert-info-bg: #E0E0E0;
  --theme-alert-info-color: var(--theme-text-main);

  --theme-alert-success-bg: #d5ecd6;
  --theme-alert-success-color: #1F8B24;

  --theme-alert-warn-bg: #f0e5d7;
  --theme-alert-warn-color: #BF710F;

  --theme-info-panel-color: #818D93;

  /* Layout */
  --theme-main-bg: #ECEFF3;
  --theme-card-bg: white;
  --theme-lines: #E8E8E8;

  /* Scrollbar */
  --theme-scrollbar-bg: #F6F6F6;
  --theme-scrollbar-thumb: var(--theme-primary);

  /* Input */
  --theme-input-border: #CAD2DA;
  --theme-input-focused: #0075EB;
  --theme-input-placeholder: #979797;
  --theme-input-label: var(--theme-text-secondary);
  --theme-input-photo-bg: var(--theme-input-border);

  /** Toolbar */
  --theme-toolbar-bg: #72879E;
  --theme-toolbar-color: #FEFEFE;

  /** Sidebar */
  --theme-sidebar-bg: var(--theme-card-bg);
  --theme-sidebar-color: var(--theme-text-secondary);
  --theme-sidebar-active: #343E48;

  /** Buttons */
  --theme-button-disabled-color: var(--theme-input-border);
  --theme-icon-button-bg: #FFFFFF;
  --theme-icon-button-color: #5F676B;
  --theme-icon-button-hover-bg: linear-gradient(180deg, #46585C 0%, #0C1517 100%);
  --theme-icon-button-hover-color: var(--theme-icon-button-bg);
  --theme-icon-button-shadow: 0 6px 20px 0 #3838381F;

  --theme-flat-button-border-radius: 3.875rem;
  --theme-flat-button-primary-bg: var(--theme-primary);
  --theme-flat-button-primary-color: var(--theme-card-bg);
  --theme-flat-button-primary-border: var(--theme-flat-button-primary-bg);
  --theme-flat-button-primary-hover-bg: var(--theme-flat-button-primary-bg);
  --theme-flat-button-primary-hover-color: var(--theme-flat-button-primary-color);
  --theme-flat-button-primary-hover-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-hover-opacity: 0.75;
  --theme-flat-button-primary-disabled-bg: #BBBBBB;
  --theme-flat-button-primary-disabled-color: var(--theme-card-bg);
  --theme-flat-button-primary-disabled-border: var(--theme-flat-button-primary-disabled-color);
  --theme-flat-button-primary-disabled-opacity: 0.5;

  --theme-flat-button-secondary-bg: #F7F7F7;
  --theme-flat-button-secondary-color: var(--theme-primary);
  --theme-flat-button-secondary-border: var(--theme-text-accent);
  --theme-flat-button-secondary-hover-bg: var(--theme-flat-button-secondary-bg);
  --theme-flat-button-secondary-hover-color: #2F80ED;
  --theme-flat-button-secondary-hover-border: var(--theme-flat-button-secondary-hover-border);
  --theme-flat-button-secondary-active-bg: var(--theme-flat-button-secondary-bg);
  --theme-flat-button-secondary-active-color: #1363D0;
  --theme-flat-button-secondary-active-border: var(--theme-flat-button-secondary-hover-border);
  --theme-flat-button-secondary-disabled-bg: var(--theme-flat-button-secondary-bg);
  --theme-flat-button-secondary-disabled-color: var(--theme-flat-button-secondary-color);
  --theme-flat-button-secondary-disabled-border: var(--theme-flat-button-secondary-border);
  --theme-flat-button-secondary-disabled-opacity: 0.5;

  /** Table */
  --theme-table-header-bg: var(--theme-main-bg);
  --theme-table-header-color: #111827;
  --theme-table-header-border: var(--theme-main-bg);
  --theme-table-cell-border: var(--theme-input-border);
  --theme-table-row-selected-bg: #F4F4F4;

  /* */
  --theme-user-avatar-bg: #CAD2DA;

  /* Radio */
  --theme-radio-unselected-border: #807F7F;
  --theme-radio-selected-border: var(--theme-primary);
  --theme-radio-icon-color: var(--theme-text-main);

  /* Pagination */
  --theme-pagination-color: var(--theme-primary);
  --theme-pagination-bg: var(--theme-card-bg);
  --theme-pagination-disabled-color: var(--theme-input-border);
  --theme-pagination-disabled-bg: var(--theme-card-bg);
  --theme-pagination-active-color: var(--theme-card-bg);
  --theme-pagination-active-bg: var(--theme-text-accent);
}
