/* You can add global styles to this file, and also import other style files */

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "quill-emoji/dist/quill-emoji.css";

@import "styles/fonts";
@import "styles/material-theme";

@import "styles/typography-classes";
@import "styles/general";
@import "styles/theme/default";
@import "styles/modify-material";
@import "styles/components";
@import "styles/pages";
